/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('aa-upgrade', {
      parent: 'root',
      url: '/aa-upgrade',
      template: '<ui-view/>',
      redirectTo: '.hub',
      data: {
        permissions: {
          only: 'isPushNotificationEnabled',
          redirectTo: {
            isPushNotificationEnabled: 'anytime-alerts'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.anytime-alerts" */ 'apps/anytime-alerts-upgrade').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('aa-upgrade.main', {
      url: '/manage-alerts',
      templateUrl: 'apps/anytime-alerts-upgrade/templates/acp-anytime-alerts.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          only: 'isUpdatedAlertTermsAccepted',
          except: 'showAnytimeAlertsMasterAccountManagement',
          redirectTo: {
            isUpdatedAlertTermsAccepted: 'aa-upgrade.review-terms',
            showAnytimeAlertsMasterAccountManagement: 'aa-upgrade.manage',
          }
        }
      }
    })
    .state('aa-upgrade.manage', {
      url: '',
      templateUrl:
        'apps/anytime-alerts-upgrade/templates/acp-anytime-alerts-master-account.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          only: 'showAnytimeAlertsMasterAccountManagement',
          redirectTo: {
            showAnytimeAlertsMasterAccountManagement: 'aa-upgrade.intro'
          }
        }
      }
    })
    .state('aa-upgrade.review-terms', {
      url: '/review-terms',
      templateUrl:
        'apps/anytime-alerts-upgrade/templates/acp-anytime-alerts-review-terms.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          except: 'isUpdatedAlertTermsAccepted',
          redirectTo: {
            isUpdatedAlertTermsAccepted:'aa-upgrade.hub'
          }
        }
      }
    })
    .state('aa-upgrade.hub', {
      url: '/hub?interrupt',
      templateUrl:
        'apps/anytime-alerts-upgrade/templates/acp-anytime-alerts-hub.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          only: 'isUpdatedAlertTermsAccepted',
          redirectTo: {
            isUpdatedAlertTermsAccepted: 'aa-upgrade.enroll',
          }
        }
      }
    })
    .state('aa-upgrade.enroll', {
      url: '/enroll',
      templateUrl:
        'apps/anytime-alerts-upgrade/templates/acp-anytime-alerts-enroll.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          except: ['hasAlertsTermsAccepted', 'isUpdatedAlertTermsAccepted'],
          redirectTo: {
            hasAlertsTermsAccepted: 'aa-upgrade.review-terms',
            isUpdatedAlertTermsAccepted: 'aa-upgrade.hub'
          }
        }
      }
    })
    .state('aa-upgrade.download-app', {
      url: '/download-app',
      templateUrl:
        'apps/anytime-alerts-upgrade/templates/acp-anytime-alerts-download-app.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl'
    });
}

export default states;
